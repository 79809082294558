import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { timezoneConfig } from 'src/app/common/constants/timezone';
import { VisitorEvent } from '../../shared/models/visitor.event';
import { frontendSettingsSelector } from '../../shared/ngrx.stores/frontend.settings/selectors';
import {
  IAppState,
  IFrontendSettings,
} from '../../shared/ngrx.stores/frontend.settings/states';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import { ConfigService } from 'src/app/common/services/config/config.service';

@Injectable({
  providedIn: 'root',
})
export class EventTimeService {
  frontendSettings$: Observable<IFrontendSettings | null>;
  defaultTimezoneFormatter: string = null;
  monthText = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  constructor(private _store: Store<IAppState>) {
    this.frontendSettings$ = this._store.pipe(select(frontendSettingsSelector));
    this.frontendSettings$.subscribe((settings) => {
      if (!settings) return;

      this.defaultTimezoneFormatter = settings.customSettings.timezoneFormatter;
    });

    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(advancedFormat);
  }

  public processEventTimes(event: VisitorEvent) {
    this.setTimezoneFormatter(event);
    this.sortEventTimes(event);

    const TODAY = new Date();
    TODAY.setHours(0, 0, 0, 0);
    const filteredTimes = [];
    for (const time of event.eventTimes) {
      if (
        (!time.endTime && new Date(time.startTime) < TODAY) ||
        (time.endTime && new Date(time.endTime) < TODAY)
      )
        continue;

      const start = dayjs(time.startTime).tz(event.timezoneFormatter);
      // const end = time.endTime ? dayjs(time.endTime).tz(event.timezoneFormatter) : null;

      const timeWithFormat = {
        startTime: time.startTime,
        endTime: time.endTime,
        timeTwoLines: this.getTimeTwoLines(time, event.timezoneFormatter),
        timeOneLine: this.getTimeOneLine(time, event.timezoneFormatter),
        month: start.format('MMM'),
        day: start.format('D'),
        year: start.format('YYYY'),
      };
      filteredTimes.push(timeWithFormat);
    }

    if (!filteredTimes.length) {
      console.warn('EVENT TIME NULL---------', event);
      return null;
    }

    event.eventTimes = filteredTimes;
    event.startTime = filteredTimes[0].startTime;
    event.endTime = filteredTimes[filteredTimes.length - 1].endTime;

    event.timeTwoLines = this.getTimeTwoLines(
      { startTime: event.startTime, endTime: event.endTime },
      event.timezoneFormatter
    );
    event.timeOneLine = this.getTimeOneLine(
      { startTime: event.startTime, endTime: event.endTime },
      event.timezoneFormatter
    );
  }

  public formatToShareTime(time: string): string {
    return dayjs(time)
      .tz(this.defaultTimezoneFormatter)
      .format(ConfigService.config.eventShare.mailDateFormat);
  }

  public toISOTime(time: string | Date, timezoneFormatter: string) {
    return dayjs.tz(time, timezoneFormatter).toISOString();
  }

  public getTZNameByFormatter(formatter: string) {
    return timezoneConfig.find((x) => x.value === formatter)?.name || formatter;
  }

  public getTZByFormatter(formatter: string) {
    return timezoneConfig.find((x) => x.value === formatter)?.code || '';
  }

  public toICSDate(time: string | Date, formatter: string) {
    return dayjs(time).tz(formatter).format('YYYY-MM-DD');
  }

  public toICSTime(time: string | Date, formatter: string) {
    return dayjs(time).tz(formatter).format('HH:mm');
  }

  public isAllDayEvent(time: string | Date, formatter: string) {
    const start = dayjs(time).tz(formatter);
    return start.hour() === 0;
  }

  public getLocalHourByTZF(time: Date | string, formatter: string) {
    const hour = dayjs(time).tz(formatter).format('HH');
    return parseInt(hour, 10);
  }

  public getDiff(timeA: Date | string, timeB: Date | string) {
    let a = dayjs(timeA);
    let b = dayjs(timeB);
    return a.diff(b, 'day');
  }

  public convertToTravelTimeText(totalTravelTime: number) {
    // return totalTravelTime / 60 > 1
    //   ? Math.floor(totalTravelTime / 60) +
    //       'hrs ' +
    //       Math.round(totalTravelTime % 60) +
    //       'min'
    //   : totalTravelTime + 'min';

    const hours = Math.floor(totalTravelTime / 3600); // Calculate hours
    const minutes = Math.floor((totalTravelTime % 3600) / 60); // Calculate remaining minutes

    let timeString = '';
    if (hours > 0) {
      timeString += hours + ' hrs';
      if (minutes > 0) {
        timeString += ', ';
      }
    }
    if (minutes > 0) {
      timeString += minutes + ' min';
    }
    return timeString;
  }

  public addToDate(
    date: Date | string,
    unit: dayjs.ManipulateType,
    amount: number
  ) {
    const currentDate = dayjs(date);
    return currentDate.add(amount, unit);
  }

  public getOnlyDisplayDate(target: Date | string) {
    const date = new Date(target);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return `${this.monthText[month]}.${day}, ${year}`;
  }

  /**
   * Check if a place is open now based on its opening hours and timezone.
   * @param {Array} periods - Array of opening and closing periods.
   * @param {string} timezone - Timezone (e.g., "America/New_York").
   * @returns {boolean} - True if the place is open, false otherwise.
   */
  isPlaceOpenNow(periods: { open; close }[], timezone: string) {
    const now = dayjs().tz(timezone);
    const currentDay = now.day(); // Sunday = 0, Monday = 1, ..., Saturday = 6
    const currentTime = now.format('HHmm');

    if (
      periods.length === 1 &&
      periods[0].open?.day === 0 &&
      periods[0].open?.time === '0000'
    ) {
      return true;
    }

    // Iterate over the periods array to check if now falls within any open-close time.
    for (const period of periods) {
      const { open, close } = period;

      if (open.day === currentDay) {
        // Check if the current time falls within the opening period.
        if (currentTime >= open.time && currentTime < close.time) {
          return true;
        }
      }
    }

    return false;
  }

  private setTimezoneFormatter(event: VisitorEvent) {
    // if (this.defaultTimezoneFormatter && !event.isManuallyCreated) {
    if (this.defaultTimezoneFormatter) {
      event.timezoneFormatter = this.defaultTimezoneFormatter;
    }

    if (!event.timezoneFormatter) {
      if (!event.timezone) {
        throw Error(`${event.name}'s timezone not found!`);
      }

      event.timezoneFormatter = timezoneConfig.find((x) =>
        x.name.includes(event.timezone)
      )?.value;

      if (!event.timezoneFormatter)
        throw Error(`${event.name}'s timezone formatter not found!`);
    }
  }

  private sortEventTimes(event: VisitorEvent) {
    if (!event.eventTimes || !event.eventTimes.length) {
      event.eventTimes = [
        { startTime: event.startTime, endTime: event.endTime },
      ];
    }
    event.eventTimes.sort((a, b) => {
      return a.startTime > b.startTime ? 1 : -1;
    });
  }

  /**
   format for modern-block
   date: Feb 10  - Feb 12, 2023
   time: Fri.3:00 PM - Sun.1:00 PM (EST)
   */
  private getTimeTwoLines(
    time: { startTime: string; endTime: string },
    timezoneFormatter: string
  ): { date: string; time: string } {
    const start = dayjs(time.startTime).tz(timezoneFormatter);
    const end = time.endTime ? dayjs(time.endTime).tz(timezoneFormatter) : null;
    let time1 = { date: '', time: '' };

    if (!end) {
      time1.date = start.format('MMM D, YYYY');
      time1.time = start.hour() === 0 ? '' : start.format('ddd, h:mm A (z)');
    } else {
      const startMonthDate = start.format('MMM D');
      const endMonthDate = end.format('MMM D');
      time1.date += startMonthDate;
      if (start.year() !== end.year()) {
        time1.date += `, ${start.year()}`;
      }
      time1.date +=
        startMonthDate === endMonthDate
          ? `, ${end.year()}`
          : ` - ${
              start.month() === end.month()
                ? end.format('D, YYYY')
                : end.format('MMM D, YYYY')
            }`;

      const endWeekday = start.day() === end.day() ? '' : 'ddd, ';
      // hour = 0 and minute = 0 means it's all day event, then hide time
      time1.time =
        (start.hour() === 0 && start.minute() === 0
          ? ''
          : start.format('ddd, h:mm A')) +
        ' - ' +
        (end.hour() === 0 && end.minute() === 0
          ? ''
          : end.format(`${endWeekday}h:mm A (z)`));

      time1.time = time1.time === ' - ' ? '' : time1.time;
      time1.time = time1.time.endsWith(' - ')
        ? time1.time.replace(' - ', '')
        : time1.time;
    }

    return time1;
  }

  /**
   format for default, long, square card
   Oct 8th @ 8:00  - Jan 5th @ 9:45 EST
   Jan 19th @ 7:30 EST
   */
  private getTimeOneLine(
    time: { startTime: string; endTime: string },
    timezoneFormatter: string
  ): string {
    const start = dayjs(time.startTime).tz(timezoneFormatter);
    const end = time.endTime ? dayjs(time.endTime).tz(timezoneFormatter) : null;

    let time2 = start.format(`MMM D @ h:mm A ${end ? '' : 'z'}`);
    if (end) {
      time2 += ` - ${
        start.format('MMM D') === end.format('MMM D')
          ? end.format('h:mm A z')
          : end.format('MMM D @ h:mm A z')
      }`;
    }

    return time2;
  }
}
