import { VisitorEvent } from './../../models/visitor.event';
import { ConfigService } from './../../../../common/services/config/config.service';
import { GoogleMapService } from './../../services/google.map.service';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { delay, map, Observable } from 'rxjs';
import {
  GoogleMap,
  MapDirectionsService,
  MapInfoWindow,
  MapMarker,
  MapMarkerClusterer,
} from '@angular/google-maps';
import { VisitorPlace } from '../../models/visitor.place';
import { ModalService } from 'src/app/common/services/modal/modal.service';
import { FancyPlaceDetailsModalComponent } from 'src/app/modules/place-calendar/components/place.details.modal/fancy.place.details.modal/fancy.place.details.modal.component';
import { IPlaceDetailsModalData } from 'src/app/modules/place-calendar/components/place.details.modal/place.details.modal.provider';
import { FancyEventDetailsModalComponent } from 'src/app/modules/event-calendar/components/event.details.modal/fancy.event.details.modal/fancy.event.details.modal.component';
import { IEventDetailsModalData } from 'src/app/modules/event-calendar/components/event.details.modal/event.details.modal.provider';
import { VisitorPlaceService } from 'src/app/modules/event-calendar/services/visitor.place.service';
import { IMapMarkerWithCustomData } from '../../models/place.marker';
import { ScriptService } from 'src/app/common/services/load.script/load.script.service';

@Component({
  selector: 'ig-google-map',
  templateUrl: './google.map.component.html',
  styleUrls: ['./google.map.component.scss'],
  host: {
    class: 'ig-google-map',
  },
  encapsulation: ViewEncapsulation.None,
})
export class GoogleMapComponent implements OnInit {
  @HostBinding('class') hostClass = 'w-full block';
  apiLoaded$: Observable<boolean>;

  // map cluster's script was loaded in the index.html
  @Input() useCluster = false;

  _markers: IMapMarkerWithCustomData<VisitorEvent | VisitorPlace>[] = [];
  @Input() set markers(
    value: IMapMarkerWithCustomData<VisitorEvent | VisitorPlace>[]
  ) {
    this._markers = value;

    setTimeout(() => {
      if (this._markers.length) {
        this.fitBounds();
        if (this._markers.length === 1) {
          this.googleMap.googleMap.setZoom(14);
        }
      } else {
        this.googleMap?.googleMap.setCenter(
          ConfigService.config.google.USCenter
        );
        this.googleMap?.googleMap.setZoom(4);
      }
    }, 700);
  }

  @Input() zoom = 14;
  _mode: 'light' | 'dark' = 'light';
  @Input() set mode(value: 'light' | 'dark') {
    this._mode = value;
    this.options &&
      (this.options.mapId =
        value === 'light'
          ? ConfigService.config.google.mapId
          : ConfigService.config.google.darkModeMapId);
  }

  @Input() useDefaultMarkerClickStyle = true;
  @Input() center: google.maps.LatLngLiteral =
    ConfigService.config.google.USCenter;

  @Output() public onCloseInfoWindow = new EventEmitter();
  @Output() public onclick = new EventEmitter();
  @Output() public onMarkerClick = new EventEmitter<MapMarker>();

  isShowEventInfoWindow = false;
  isShowPlaceInfoWindow = false;
  isShowClusterInfoWindow = false;
  clusterContent = '';

  options: google.maps.MapOptions;
  clusterPositions: IClusterPosition[] = [];

  @ViewChild(GoogleMap, { static: false }) googleMap: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @ViewChildren(MapMarker) mapMarkerObjs: QueryList<MapMarker>;
  @ViewChild(MapMarkerClusterer) markerCluster: MapMarkerClusterer;

  selectedMarker: {
    markerEle: MapMarker;
    marker: IMapMarkerWithCustomData<VisitorEvent | VisitorPlace>;
  };

  directionsResults$: Observable<google.maps.DirectionsResult | undefined>;
  directionsMarkerOptions: google.maps.DirectionsRendererOptions = {
    markerOptions: {
      opacity: 0,
    },
    suppressMarkers: true,
  };

  constructor(
    public googleMapService: GoogleMapService,
    public modalService: ModalService,
    public mapDirectionsService: MapDirectionsService,
    public scriptService: ScriptService,
    private _placeService: VisitorPlaceService,
    private _renderer: Renderer2
  ) {
    this.loadMarkerClusterScript(() => {
      this.loadMapService();
    });
  }

  ngOnInit(): void {
    this.options = {
      mapId:
        this._mode === 'light'
          ? ConfigService.config.google.mapId
          : ConfigService.config.google.darkModeMapId,
      center: ConfigService.config.google.USCenter,
      zoom: this.zoom,
    };
  }

  loadMapService() {
    this.apiLoaded$ = this.googleMapService.loadGoogleMapApi();

    this.apiLoaded$.pipe(delay(500)).subscribe(() => {
      this._markers.length && this.fitBounds();
    });
  }

  loadMarkerClusterScript(onload?: () => void) {
    const SCRIPT_PATH = `https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js`;

    if (!this.scriptService.isScriptExist(SCRIPT_PATH)) {
      const scriptElement = this.scriptService.loadJsScript(
        this._renderer,
        SCRIPT_PATH
      );
      scriptElement.onload = () => {
        console.log('marker cluster script loaded');
        onload && onload();
      };
      scriptElement.onerror = () => {
        console.log('Could not load the script: ' + SCRIPT_PATH);
      };
    } else {
      onload && onload();
    }
  }

  mapClick(event: google.maps.MapMouseEvent) {
    this.closeInfoWindow();
    this.onclick.emit();

    // close all infowindows from this.clusterPositions
    this.closeClusterInfoWindows();
  }

  private closeClusterInfoWindows() {
    for (const clusterPosition of this.clusterPositions) {
      if (clusterPosition.infowindow) {
        clusterPosition.infowindow.close();
        clusterPosition.infowindow = null;
        clusterPosition.isInfoWindowOpen = false;
      }
      if (clusterPosition.marker) {
        clusterPosition.marker.setMap(null);
        clusterPosition.marker = null;
      }
    }

    // after opening a details popup from the cluster window, back to the map, the cluster info window will not be closed, so we need to remove them
    if (this.isShowClusterInfoWindow) {
      this.shadowRoot
        .querySelectorAll('.gm-style-iw.gm-style-iw-c, .gm-style-iw-tc')
        .forEach((popup) => {
          popup.remove();
        });
    }

    this.isShowClusterInfoWindow = false;
  }

  /**
   * This function is called when the MapMarkerClusterer is initialized and updated
   * Adding to hovering popup to the clusters
   * @param $event holds the MapMarkerClusterers, when mouse is on a cluster, it will check the cluster position and get the one from the $event (all clusters will be addded to this.clusterPositions)
   */
  clustererEnd($event) {
    this.closeClusterInfoWindows();
    this.getPositionOfEachCluster($event);

    this.removeClusterListener();

    setTimeout(() => {
      const clusters = this.shadowRoot.querySelectorAll(
        '.ig-google-map .cluster'
      );
      clusters.forEach((cluster) => {
        const rect = cluster.getBoundingClientRect();

        cluster.addEventListener('mouseenter', (e) => {
          for (const clusterPosition of this.clusterPositions) {
            if (
              clusterPosition.rect.x === rect.x &&
              clusterPosition.rect.y === rect.y
            ) {
              // if infowindow is already open
              if (!clusterPosition.isInfoWindowOpen) {
                console.log('clusterPosition.cluster', clusterPosition);

                this.isShowClusterInfoWindow = true;
                const container =
                  this.generateClusterHoveringPopupContent(clusterPosition);

                this.generateAndShowClusterInfoWindow(
                  container,
                  clusterPosition
                );
              }
            } else {
              if (clusterPosition.infowindow) {
                clusterPosition.infowindow.close();
                clusterPosition.infowindow = null;
                clusterPosition.isInfoWindowOpen = false;
              }
            }
          }
        });
      });
    }, 200);
  }

  private generateAndShowClusterInfoWindow(
    container: HTMLDivElement,
    clusterPosition: {
      cluster: any;
      rect: DOMRect;
      infowindow: google.maps.InfoWindow;
      isInfoWindowOpen: boolean;
      marker: google.maps.Marker;
      el: HTMLElement;
    }
  ) {
    const infowindow = new google.maps.InfoWindow({
      content: container,
      maxWidth: window.innerWidth * 0.2,
      minWidth: window.innerWidth * 0.2,
    });

    const marker = new google.maps.Marker({
      position: {
        lat: clusterPosition.cluster.center_.lat(),
        lng: clusterPosition.cluster.center_.lng(),
      },
      map: this.googleMap.googleMap,
      opacity: 0,
    });

    infowindow.open({
      anchor: marker,
      map: this.googleMap.googleMap,
    });

    clusterPosition.infowindow = infowindow;
    clusterPosition.isInfoWindowOpen = true;
    clusterPosition.marker = marker;

    infowindow.addListener('closeclick', () => {
      this.isShowClusterInfoWindow = false;
      clusterPosition.infowindow = null;
      clusterPosition.isInfoWindowOpen = false;
      marker.setMap(null);
    });
  }

  private generateClusterHoveringPopupContent(
    clusterPosition: IClusterPosition
  ) {
    const container = document.createElement('div');
    container.className = 'p-4';
    const grid = document.createElement('div');
    grid.className = `grid grid-cols-${
      clusterPosition.cluster.markers_.length === 2 ? 2 : 3
    } bg-white gap-3`;
    clusterPosition.cluster.markers_.forEach((marker, index) => {
      if (index < 6) {
        // marker.title is the _id of the place, find the place by _id from marker
        const placeMarker = this._markers.find(
          (m) => m.data._id === marker.title
        );

        if (placeMarker) {
          const div = document.createElement('div');
          div.innerHTML = `
                      <div class="flex flex-col gap-2 items-center overflow-hidden cursor-pointer group">
                        <img src="${placeMarker.data.cover?.source}" class="w-full h-[8vh] rounded-md group-hover:scale-[1.05] transition-all" />
                        <p class="text-sm group-hover:font-semibold group-hover:text-gray-600 transition-all">${placeMarker.data.title}</p>
                      </div>
                    `;
          this._renderer.listen(div, 'click', () => {
            if (placeMarker.data.startTime) {
              this.goToEvent(placeMarker.data as VisitorEvent);
            } else {
              this.goToPlace(placeMarker.data as VisitorPlace);
            }
          });
          this._renderer.appendChild(grid, div);
        }
      }
    });

    this._renderer.appendChild(container, grid);
    if (clusterPosition.cluster.markers_.length > 6) {
      // add a more button
      const div = document.createElement('div');
      div.innerHTML = `
                  <div class="flex flex-col gap-2 items-center overflow-hidden mt-4">
                    <p class="text-sm bg-blue-400 text-white px-3 py-1.5 rounded-sm uppercase cursor-pointer font-semibold">Click to see more</p>
                  </div>
                `;

      // click on the SEE MORE button = click on the cluster to zoom in
      this._renderer.listen(div, 'click', () => {
        clusterPosition.el.click();
      });

      this._renderer.appendChild(container, div);
    }
    return container;
  }

  private removeClusterListener() {
    const clusters = this.shadowRoot.querySelectorAll('.cluster');
    clusters.forEach((cluster) => {
      cluster.removeEventListener('mouseenter', () => {});
    });
  }

  private getPositionOfEachCluster($event: any) {
    setTimeout(() => {
      this.clusterPositions = [];
      $event.clusters_.forEach((cluster) => {
        const rect = cluster.clusterIcon_.div_.getBoundingClientRect();
        this.clusterPositions.push({
          cluster,
          rect,
          infowindow: null,
          isInfoWindowOpen: false,
          marker: null,
          el: cluster.clusterIcon_.div_,
        });
      });
    }, 100);
  }

  public get imgoingWrapper() {
    return (
      document.getElementById('imgoingcalendar-wrapper') ||
      document.getElementById('imgoingcalendar-wrapper-wix')
    );
  }

  public get shadowRoot() {
    return (this.imgoingWrapper || document).querySelector('app-root')
      .shadowRoot;
  }

  markerClick(
    markerEle,
    marker: IMapMarkerWithCustomData<VisitorEvent | VisitorPlace>
  ) {
    if (this.useDefaultMarkerClickStyle) {
      this._markers.forEach((m) => {
        m.options = {
          icon: null,
          animation: null,
          zIndex: 1,
        };
      });
      marker.options = {
        icon: 'https://iti-images.s3.amazonaws.com/imgs/marker-xs.png',
        animation: google.maps.Animation.BOUNCE,
        zIndex: 2,
      };
      this.center = {
        lat: marker.position.lat,
        lng: marker.position.lng,
      } as google.maps.LatLngLiteral;
    }

    this.selectedMarker = {
      markerEle,
      marker,
    };
    this.onMarkerClick.emit(marker);
  }

  showEventInfoWindow(
    marker?: MapMarker,
    data?: IMapMarkerWithCustomData<VisitorEvent>
  ) {
    this.isShowPlaceInfoWindow = false;
    this.isShowEventInfoWindow = true;
    if (marker && data) {
      this.selectedMarker = {
        markerEle: marker,
        marker: data,
      };
    }
    this.infoWindow.open(this.selectedMarker.markerEle);
  }

  showPlaceInfoWindow(
    marker?: MapMarker,
    data?: IMapMarkerWithCustomData<VisitorPlace>
  ) {
    this.isShowEventInfoWindow = false;
    this.isShowPlaceInfoWindow = true;
    if (marker && data) {
      this.selectedMarker = {
        markerEle: marker,
        marker: data,
      };
    }
    this.infoWindow.open(this.selectedMarker.markerEle);
  }

  closeInfoWindow() {
    this.isShowEventInfoWindow = false;
    this.isShowPlaceInfoWindow = false;
    this.infoWindow?.close();
    this.onCloseInfoWindow.emit();
  }

  goToEvent(event: VisitorEvent) {
    this.modalService.show({
      component: FancyEventDetailsModalComponent,
      panelClass: 'ig-modal-w-full',
      data: {
        currentEvent: event,
        allEvents: [],
        favCustomEvents: [],
        from: 'placeCalendar',
      } as IEventDetailsModalData,
    });
  }

  goToPlace(place: VisitorPlace) {
    if (place.isManuallyCreated || place.socialName) {
      this.modalService.show({
        component: FancyPlaceDetailsModalComponent,
        panelClass: 'ig-modal-w-full',
        data: {
          currentPlace: place,
        } as IPlaceDetailsModalData,
      });
    } else {
      // for the places from TravelBuddy suggestions, some of them are not in ImGoing, but they are searched from Google when TravelBuddy generating the suggestions, show their websites
      if (place.website) {
        window.open(place.website, '_blank');
      }
    }
  }

  async drawDirectionRoute(
    locations: { lat: number; lng: number; stopover?: boolean }[] = [],
    travelMode = google.maps.TravelMode.DRIVING,
    option: { optimizeWaypoints: boolean } = { optimizeWaypoints: false }
  ) {
    if (!locations || locations.length <= 1) return;

    // remove first and last element
    const waypoints = locations.slice(1, -1).map((x) => {
      return {
        location: { lng: x.lng, lat: x.lat },
        stopover: x.stopover ?? true,
      };
    });

    const request: google.maps.DirectionsRequest = {
      destination: locations.last(),
      origin: locations.first(),
      waypoints: waypoints,
      travelMode: travelMode,
      ...option,
    };

    // const request: google.maps.DirectionsRequest = {
    //   destination: {lat: 12, lng: 4},
    //   origin: {lat: 14, lng: 8},
    //   travelMode: google.maps.TravelMode.DRIVING
    // };

    this.directionsResults$ = this.mapDirectionsService
      .route(request)
      .pipe(map((response) => response.result));
    // .subscribe((directionsResults) => {
    //   console.log('directionsResults', directionsResults);
    //   const lat = directionsResults.routes[0].overview_path[0].lat();
    //   const lng = directionsResults.routes[0].overview_path[0].lng();
    //   console.log('lat', lat);
    //   console.log('lng', lng);
    // });

    // this.apiLoaded$.pipe(delay(500)).subscribe(() => {
    //   console.log('locations', locations);

    //   // const request: google.maps.DirectionsRequest = {
    //   //   destination: locations.last(),
    //   //   origin: locations.first(),
    //   //   waypoints: locations.slice(1, -1), // remove first and last element //[{ location: { lat: 13, lng: 6 }, stopover: true }],
    //   //   travelMode: google.maps.TravelMode.DRIVING,
    //   // };

    //   // this.fitBounds();
    // });
  }

  getPixelPositionByLatLng(
    latlng: google.maps.LatLng
  ): Promise<{ x: number; y: number }> {
    return new Promise<{ x; y }>((resolve, reject) => {
      let overlay = new google.maps.OverlayView();
      overlay.draw = function () {};
      overlay.onAdd = function () {
        let projection = this.getProjection();
        let pixel = projection.fromLatLngToContainerPixel(latlng);
        resolve(pixel);
      };
      overlay.setMap(this.googleMap.googleMap);
    });
  }

  getDisplayCategory(place: VisitorPlace) {
    return this._placeService.getDisplayCategory(place);
  }

  setNoContent() {
    this.center = ConfigService.config.google
      .USCenter as google.maps.LatLngLiteral;
    this.options = {
      ...this.options,
      zoom: 5,
    };
  }

  public fitBounds(padding?: number) {
    try {
      if (!window['google']) return;

      if (this._markers.length === 1) {
        this.center = {
          lat: this._markers[0].position.lat as number,
          lng: this._markers[0].position.lng as number,
        } as google.maps.LatLngLiteral;
        return;
      }

      let bounds = new google.maps.LatLngBounds();
      this._markers.forEach((marker: any) => {
        bounds.extend(
          new google.maps.LatLng(marker.position.lat, marker.position.lng)
        );
      });
      this.googleMap?.fitBounds(bounds, padding);
    } catch (error) {
      console.log(error);
    }
  }
}

export interface IClusterPosition {
  cluster: any;
  rect: DOMRect;
  infowindow: google.maps.InfoWindow;
  isInfoWindowOpen: boolean;
  marker: google.maps.Marker;
  el: HTMLElement;
}
